import { useState, useEffect } from "react";
import AdminHeader from "../../components/admin/AdminHeader"
import Card from "../../components/admin/Card"
import SideNav from "../../components/admin/SideNav";
import axios from "axios";

function Admin() {
    const [activeEmployer, setActiveEmployer] = useState(null);
    const [activeEmployee, setActiveEmployee] = useState(null);
    const [activeEmployed, setActiveEmployed] = useState(null);
    const [employerData, setEmployerData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [employedData, setEmployedData] = useState([]);
    const employer_id = JSON.parse(localStorage.getItem('user'))._id
    const [tabs, setTabs] = useState({
        employerTab: true,
        employeeTab: true,
        employedTab: true,
    });

    async function getEmployers() {
        var res = await axios.get('/api/employers/getpaginated');
        const { data } = res;
        setEmployerData(data.data);
    };
    async function getEmployees() {
        var res = await axios.get('/api/employees/getpaginated');
        const { data } = res;
        setEmployeeData(data.data);
    };
    async function getEmployed() {

    };
    const handleAssign = async (employerId, employeeId) => {
        try {
            await axios.post('/api/employees/assign', {
                employerId: employerId,
                employeeId: employeeId
            });
            var res = await axios.get(`/api/employees/getemployeremployees/${employerId}`);
            const { data } = res;
            setEmployedData(data.data);

        } catch (error) {

        }
    };
    const handleUnassign = async (employerId, employeeId) => {
        try {
          const response = await axios.put('/api/employees/unassign', {
            employerId: employerId,
            employeeId: employeeId,
          });
          var res = await axios.get(`/api/employees/getemployeremployees/${employerId}`);
          const { data } = res;
          setEmployedData(data.data);
          
        } catch (error) {
        }
      };

    let employers = employerData;
    let employees = employeeData;
    let employed = employedData;



    useEffect(() => {
        getEmployers();
        getEmployees();
        getEmployed();
    }, []);
    const handleTabs = (tab) => {
        setTabs({ ...tabs, ...tab });
    }


    const handleActiveEmployer = async (id) => {
        if (activeEmployer === id) {
            setActiveEmployer(null);
            setEmployedData([]);
            return;
        }
        var res = await axios.get(`/api/employees/getemployeremployees/${id}`);
        const { data } = res;
        setEmployedData(data.data);
        setActiveEmployer(id);
    };
    const handleActiveEmployee = async (id) => {
        if (activeEmployee === id) {
            setActiveEmployee(null);
            return;
        }
        setActiveEmployee(id);
    };
    const handleActiveEmployed = async (id) => {
        if (activeEmployed === id) {
            setActiveEmployed(null);
            return;
        }
        setActiveEmployed(id);
    };

    return (
        <>
            <div className="d-flex flex-column">
                <AdminHeader handleTabs={handleTabs} tabs={tabs} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <SideNav />
                        </div>
                        <div className="col-lg-10 ps-lg-5 mt-5">
                            <div className="row d-flex">
                                {tabs.employerTab ?
                                    <div className="col-lg-4 mb-5 mb-lg-0 mb-md-0">
                                        <h4 className="fs-3">Employers</h4>
                                        <div className="card" style={{ height: '70vh' }}>
                                            <div className="card-header">
                                                List of Employers
                                            </div>
                                            <div className="card-body overflow-auto" data-spy="scroll" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        employers.map((employer) => { return <Card id={ employer._id} active={activeEmployer === employer._id} handleActive={() => handleActiveEmployer(employer._id)} name={employer.first_name + " " + employer.last_name} key={employer._id} /> })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {tabs.employeeTab ?
                                    <div className="col-lg-4 mb-5 mb-lg-0 mb-md-0">
                                        <h4 className="fs-3">Employees</h4>
                                        <div className="card" style={{ height: '70vh' }}>
                                            <div className="card-header">
                                                List of employable users
                                            </div>
                                            <div className="card-body overflow-auto" data-spy="scroll" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <div className={`${activeEmployer == null ? 'd-none' : ''}`}>
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            employees.map((employee) => { return <Card id={ employee._id} active={activeEmployee === employee._id} handleActive={() => handleActiveEmployee(employee._id)} name={employee.first_name + " " + employee.last_name} key={employee._id} /> })
                                                        }
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="card-footer text-body-secondary p-0">
                                                <button type="button" class="btn btn-outline-primary w-100" style={{
                                                    borderTopLeftRadius: '0px',
                                                    borderTopRightRadius: '0px',
                                                    borderBottomLeftRadius: '4px',
                                                    borderBottomRightRadius: '4px'
                                                }}
                                                    onClick={() => { handleAssign(activeEmployer, activeEmployee) }}>Assign</button>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {tabs.employedTab ?
                                    <div className={`col-lg-4 mb-5 mb-lg-0 mb-md-0`}>
                                        <h4 className="fs-3">Employed</h4>
                                        <div className="card" style={{ height: '70vh' }}>
                                            <div className="card-header">
                                                List of employed users
                                            </div>
                                            <div className="card-body" data-spy="scroll overflow-auto" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        employed.map((employed) => { return <Card id={ employed._id} active={activeEmployed === employed._id} handleActive={() => handleActiveEmployed(employed._id)} name={employed.first_name + " " + employed.last_name} key={employed._id} /> })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="card-footer text-body-secondary p-0">
                                                <button type="button" class="btn btn-outline-primary w-100" style={{
                                                    borderTopLeftRadius: '0px',
                                                    borderTopRightRadius: '0px',
                                                    borderBottomLeftRadius: '4px',
                                                    borderBottomRightRadius: '4px'
                                                }}
                                                onClick={() => { handleUnassign(activeEmployer, activeEmployed) }}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin