import { useState } from "react"

function SideNav() {

    const activeId = window.location.pathname;
   
    return (
        <>
            <nav id="navbar-example3" className="flex-column align-items-stretch pe-4 border-end mt-5 d-none d-lg-block d-md-block h-100">
                <nav className="nav nav-pills flex-column ">
                    <a className={`nav-link ${activeId == '/admin'? 'active': ''}`} href="/admin">Employment Board</a>
                    <a className={`nav-link ${activeId == '/time'? 'active': ''}`} href="/time">Time Card</a>
                    <a className={`nav-link ${activeId =='/user-list'? 'active': ''}`} href="/user-list">User List</a>
                </nav>
            </nav>
            
        </>
    )
}

export default SideNav