import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CustomBullet from '../../assets/CustomBullet.png'



const industriesData = [
  {
    title: "Administrative & Clerical",
    id: "administrative",
    description:
      "Just like you need reliable staff to fill your administrative, clerical, and office needs – you need a reliable office temp agency that can deliver. That’s us.",
    subtitle: "Administrative & Office Positions We Fill: ",
    positions: [
      "Receptionist",
      "Administrative Assistant",
      "Office Assistant",
      "Office Manager",
      "Accounts Payable",
      "Collection Clerk",
      "Bookkeepers",
      "And More"
    ],
    image: require("../../assets/female-administrative2.jpg")
  },
  {
    title: "Construction & Labour",
    id: "construction",
    description:
      "We have a proven track record of filling job site workforce needs faster with quality and reliable construction workers. We’re your dependable source for all your construction labour staffing needs.",
    subtitle: "Construction Workers & General Laborers Positions We Fill:",
    positions: [
      "Carpenter",
      "Journeyman Carpenter",
      "Roofer",
      "Painter",
      "Drywall Installers",
      "Framer",
      "General Laborer",
      "Machinists",
      "And More"
    ],
    image: require("../../assets/male-construction2.jpg")
  },
  {
    title: "Customer Service",
    id: "customerservice",
    description:
      "When it comes to hiring for customer service roles, we’re a leader in the industry, providing qualified candidates for your customer service needs.",
    subtitle: "Customer Service & Call Center Positions We Fill:",
    positions: [
      "Call Center Representative",
      "Customer Service Specialist",
      "Client Services Coordinator",
      "Technical Support",
      "Telemarketer",
      "And More"
    ],
    image: require("../../assets/female-customer service.jpg")
  },
  {
    title: "Manufacturing",
    id: "manufacturing",
    description:
      "Our staffing agency delivers experienced workers for your manufacturing business, ensuring a productive and skilled workforce.",
    subtitle: "Manufacturing & Industrial Jobs We Recruit For:",
    positions: [
      "Machine Operator",
      "Assembly Line Worker",
      "Quality Control Inspector",
      "Production Supervisor",
      "Fabricator",
      "Forklift Operator",
      "And More"
    ],
    image: require("../../assets/male-manufacturing.jpg")
  },
  {
    title: "Warehouse",
    id: "warehouse",
    description:
      "We provide staffing solutions for all your warehouse and distribution needs, from loading and unloading to inventory management.",
    subtitle: "Warehouse Positions We Fill:",
    positions: [
      "Warehouse Associate",
      "Shipping and Receiving Clerk",
      "Inventory Specialist",
      "Forklift Operator",
      "Order Picker",
      "Logistics Coordinator",
      "And More"
    ],
    image: require("../../assets/male-warehouse2.jpg")
  },
  {
    title: "Skilled Trade",
    id: "skilledtrade",
    description:
      "We connect skilled tradespeople with businesses that need quality workers who can get the job done right.",
    subtitle: "Tradespeople Positions We Fill:",
    positions: [
      "Electrician",
      "Plumber",
      "Welder",
      "HVAC Technician",
      "Heavy Equipment Operator",
      "Mechanic",
      "And More"
    ],
    image: require("../../assets/male-skilled trades.jpg")
  },
];

const Industries = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation(); // Initialize useLocation

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.substring(1);
      const index = industriesData.findIndex(industry => industry.id === hash);
      if (index !== -1) {
        handleButtonClick(index);
        const button = document.querySelector(`button[data-bs-slide-to="${index}"]`);
        if (button) {
          button.click();
        }
      }
    }
  }, [location]);

  return (
    <>
      <div style={{ height: '100vh' }} className="d-flex flex-column min-vh-100">
        <Header />
        <div className='container d-none d-lg-block'>


          <div id="carouselExampleDark" className="carousel carousel-dark slide " data-bs-ride="carousel">

            <div className="carousel-inner mb-5 pb-5">

              <div className="carousel-item active" id="admin" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[0].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[0].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>Just like you need reliable staff to fill your administrative, clerical, and office
                          needs – you need a reliable office temp agency that can deliver. That’s us.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[0].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[0].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" id="construction" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[1].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[1].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>We have a proven track record of filling job site workforce needs faster with quality and reliable construction workers. 
                          We’re your dependable source for all your construction labour staffing needs.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[1].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[1].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" id="customer" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[2].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[2].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>When you need to hire high-quality and reliable staff, count on PrairieJobs for your call center and customer service needs.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[2].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[2].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" id="manufacturing" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[3].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[3].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>We have a proven track record of filling positions faster with quality and reliable talent. We’re your dependable manufacturing staffing agency for your recruitment needs.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[3].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[3].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" id="warehouse" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[4].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[4].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>When you have warehouse jobs to be filled, you need a dependable warehouse staffing agency that can fill positions quickly with reliable talent. PrairieJobs expertise can deliver.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[4].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[4].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )

                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item" id="trade" data-bs-interval="10000">
                <div className='row mt-5 pt-5 gx-4'>
                  <div className='col-lg-4'>
                    <h1 className='fw-light mb-5'>{industriesData[5].title}</h1>
                    <img className="img-fluid rounded" src={industriesData[5].image} />
                  </div>
                  <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                    <div>
                      <div className='mb-5 text-start'>
                        <p>Companies like yours trust PrairieJobs’ proven track record of filling positions faster with quality and reliable trade workers. We’re your dependable source for all your skilled labour staffing needs.
                        </p>
                      </div>
                      <div>
                        <h3 className='fw-bold'>
                          {industriesData[5].subtitle}
                        </h3>
                      </div>
                      <hr className='mb-3'></hr>
                      {industriesData[5].positions.map((pos, index) => {
                        return (
                          <div className='mb-4 d-flex'>
                            <img className="me-3" src={CustomBullet} width="24" height="24" /> {pos}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <button
  className="carousel-control-prev"
  type="button"
  data-bs-target="#carouselExampleDark"
  data-bs-slide="prev"
  style={{ left: '-10%' }}
  onClick={() => handleButtonClick(activeIndex === 0 ? 5 : activeIndex - 1)} // Adjust for wrap-around
>
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button
  className="carousel-control-next"
  type="button"
  data-bs-target="#carouselExampleDark"
  data-bs-slide="next"
  style={{ right: '-10%' }}
  onClick={() => handleButtonClick((activeIndex + 1) % 6)} // Adjust for wrap-around
>
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>
          </div>

          <div className="row mt-5 mb-5 pb-5">
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 0 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1" onClick={() => handleButtonClick(0)}>Administrative</button>
            </div>
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 1 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2" onClick={() => handleButtonClick(1)}>Construction</button>
            </div>
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 2 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3" onClick={() => handleButtonClick(2)}>Customer Service</button>
            </div>
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 3 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4" onClick={() => handleButtonClick(3)}>Manufacturing</button>
            </div>
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 4 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5" onClick={() => handleButtonClick(4)}>Warehouse</button>
            </div>
            <div className="col-lg-2">
              <button type="button" className={`btn btn-light ${activeIndex === 5 ? 'active' : ''}`} data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 6" onClick={() => handleButtonClick(5)}>Skilled Trade</button>
            </div>
          </div>

        </div>

        <div className="container mb-5 pb-5 d-lg-none">
        {industriesData.map((industry, index) => {
          return (

            <div className='row mt-5 pt-5 gx-5'>
              <>
                <div className='col-lg-4'>
                  <h1 className='fw-light mb-5'>{industry.title}</h1>
                  <img className="img-fluid rounded" src={industry.image} />
                </div>
                <div className='col-lg-8 d-flex justify-content-end me-0 ps-lg-5'>
                  <div>
                    <div className='mb-5 text-start'>
                      <p>Just like you need reliable staff to fill your administrative, clerical, and office
                        needs – you need a reliable office temp agency that can deliver. That’s us.
                      </p>
                    </div>
                    <div>
                      <h3 className='fw-bold'>
                        {industry.subtitle}
                      </h3>
                    </div>
                    <hr className='mb-3'></hr>
                    {industry.positions.map((pos, index) => {
                      return (
                        <div className='mb-4 d-flex'>
                          <img className="me-3" src={CustomBullet} /> {pos}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </>

            </div>
          )
        })}
        </div>
        <Footer />
      </div>


    </>

  );
};

export default Industries;
