import Footer from "../../components/Footer"
import Workers from "../../assets/About-Workers.png"
import Accountability from '../../assets/value icons/Accountability.png'
import Activelearning from '../../assets/value icons/Activelearning.png'
import Competition from '../../assets/value icons/Competition.png'
import Engaged from '../../assets/value icons/Engaged.png'
import Gratitude from '../../assets/value icons/Gratitude.png'
import Vulnerability from '../../assets/value icons/Vulnerability.png'
import Logo from "../../assets/image.png";
function About() {
  return (
    <>
      <style>
        {`
         @media (min-width: 992px) {
    .custom-width {
      width: 50%;
    }
         
        `}
      </style>
      <div className="d-flex flex-column">
        <div className="fixed-top">
          <div className="bg-light text-center py-2">
            <p className="m-0 fs-6 p-lg-0 px-4" style={{ color: '#333' }}>Employment Agency for Temporary, Temp-to-Hire, and Permanent Positions</p>
          </div>
          <nav className="navbar navbar-expand-lg  " style={{ background: 'white' }}>
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top mr-2" />
                <b>PrairieJobs</b>
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav ms-lg-auto ">
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/">Home</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/employees">Job Seekers</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/employers">Employers</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/industries">Industries</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/about">About</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/login">Sign In</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center ms-lg-3">
                    <a className="btn btn-outline-primary px-4" href="/contact" role="button">Join</a>
                  </li>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <div className="container pt-5 mt-5 pb-5 mb-5 d-flex justify-content-center">
          <div className="row gx-5 pb-lg-5 mb-lg-5 mt-5">
            <div className="col-lg-6 ">
              <div>
                <h1 className="fs-1">What we believe in</h1>
              </div>
              <div>
                <p className="fs-3 mt-lg-5 pe-lg-5 ">
                  We establish and build long-term, authentic relationships with our clients and our talent,
                  empowering us to consistently deliver strong results based on collaboration and trust.
                  These unique principles are the foundation of everything we believe in – driving success for talent and clients alike.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <img src={Workers} class="img-fluid" alt="..." />
            </div>
          </div>
        </div>


        <div className="container-fluid mt-5 text-center text-white p-lg-5 " style={{ background: '#105DBC' }}>
          <div className="container custom-width p-5">
            <div>
              <h1>The values that we live by</h1>
            </div>
            <div>
              <p className="fw-semibold mt-3 fs-6">
                PrairieJobs operates under a system of principles that guides our approach to each engagement. It is part employee handbook,
                part manifesto—the corporate glue that holds it all together, helping us focus more on what matters—the customer. We own this—from start to finish.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid pt-5 pb-5" style={{ background: '#f4f6f9' }}>
          <div className="container pt-5 pb-5">
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Accountability} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Accountability</h1>
                <p>Establish transparency through openness.</p>
              </div>
            </div>
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Vulnerability} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Vulnerability</h1>
                <p>Build connections through authenticity</p>
              </div>
            </div>
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Gratitude} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Gratitude</h1>
                <p>Appreciate life's experiences-- both the good and the bad</p>
              </div>
            </div>
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Competition} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Competition</h1>
                <p>Have a passion for winning</p>
              </div>
            </div>
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Activelearning} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Active Learning</h1>
                <p>Always be in pursuit to grow personally and professionally</p>
              </div>
            </div>
            <div className=" d-flex">
              <div className="d-flex align-items-center pr-3">
                <img src={Engaged} class="img-fluid" height="100%" alt="..." />
              </div>
              <div className="w-100">
                <hr />
                <h1>Engaged</h1>
                <p>Be immersed in the hustle--work can be fun, fulfilling, and exciting</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-20 pb-10">
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-4">
              <h1 className="fs-2">Our Office</h1>
              <h5 className="mt-3 mb-5">We invite employees to stop by our office!</h5>
              <p><strong>Office Hours:</strong> Monday to Friday, 8:00 am to 5:00 pm</p>
              <p><strong>Address:</strong> 4270 E Buckingham Dr., Regina, SK, S4V 3Y9</p>
              <p><strong>Phone:</strong> (306) 565-0789</p>
              <p><strong>Email:</strong> Contact@PrairieJobs.ca</p>
            </div>
            <div className="col-lg-6 col-md-20 mb-12">
              <div className="w-100">
                <iframe
                  title="Office Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12672.639611907237!2d-104.68215316965129!3d50.44005841104448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53efcb6b60e37425%3A0xf9c1e3d368a33a37!2s4270%20E%20Buckingham%20Dr%2C%20Regina%2C%20SK%20S4V%203Y9!5e0!3m2!1sen!2sca!4v1630300980463!5m2!1sen!2sca"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default About