import { useEffect, useState } from "react";
import Header from "../../components/admin/Header";
import SideNav from "../../components/admin/SideNav";
import axios from "axios";

function UserList() {
  const [roles, setRoles] = useState({
    employee: true,
    employer: true,
  });
  const [users, setUsers] = useState([]);

  const handleGetUserBasedOnFilter = async () => {
    try {
      const selectedRoles = Object.keys(roles).filter((role) => roles[role]);
      const response = await axios.post(
        "http://localhost:4000/api/admins/get-users-based-on-filter",
        { roles: selectedRoles },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Users:", response.data);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    handleGetUserBasedOnFilter();
  }, [roles]);

  const handleRoleChange = (e) => {
    const { name, checked } = e.target;
    setRoles((prevRoles) => ({
      ...prevRoles,
      [name]: checked,
    }));
  };

  return (
    <>
      <div style={{ height: "90vh" }}>
        <Header />
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-2 d-none d-lg-block d-md-block">
              <SideNav />
            </div>
            <div className="col-lg-10 col-md-10">
              {/* Role Filter */}
              <div className="mb-3">
                <label className="form-label">Filter by Role:</label>
                <div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="employeeCheck"
                      name="employee"
                      checked={roles.employee}
                      onChange={handleRoleChange}
                    />
                    <label className="form-check-label" htmlFor="employeeCheck">
                      Employee
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="employerCheck"
                      name="employer"
                      checked={roles.employer}
                      onChange={handleRoleChange}
                    />
                    <label className="form-check-label" htmlFor="employerCheck">
                      Employer
                    </label>
                  </div>
                </div>
              </div>

              {/* User List */}
              <div className="d-flex flex-wrap">
                {users.map((user) => (
                  <div key={user._id} className="col-lg-4 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">{user.name}</h5>
                        <p className="card-text">{user.email}</p>
                        <p className="card-text">{user.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserList;
