function EmployerCard({name, id, handleActive, active}) {

    return (
        <li
        type="button"
        className={`list-group-item d-flex justify-content-between align-items-start ${active ? 'active rounded' : ''}`}
        onClick={() => handleActive()} 
        style={{ userSelect: 'none' }}
      >
        <div className="ms-2 me-auto" htmlFor={id}>
          <div className="fw-bold">{name}</div>
          company maybe etc
        </div>
        <span>
          <input
            id={id}
            className="form-check-input me-1 ms-lg-5"
            type="checkbox"
            checked={active}
            onClick={(e) => e.stopPropagation()} 
            onChange={() => handleActive()}
          />
        </span>
      </li>
    )
}

export default EmployerCard
