import "../../App.css";
import Header from '../../components/Header';
import Footer from "../../components/Footer"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeBanner from "../../assets/HomeBanner.png";
import Manufacturing from "../../assets/male-manufacturing-jobs.png";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

const Employers = () => {

  return (
    <>
      <style>
        {`
         .accordion-button {
  border: none; 
  outline: none; 
  box-shadow: none; 
  color: black;
}

.accordion-button:focus {
  box-shadow: none;
  outline: none; 
  background-color: transparent;
  color: black;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: black;
}

.accordion-button:active {
  background-color: transparent;
  border: none;
  color: black;
}
  .accordion-button:active,
.accordion-button:focus-visible,
.accordion-button[aria-expanded="true"] {
    outline: none;
    border: none; /* Ensure no border in all active states */
    box-shadow: none;
}

.accordion-item {
  border: none;
        `}
      </style>
      <div style={{ height: '100vh' }} className="d-flex flex-column min-vh-100">
        <Header />
        <div className="text-center fw-bold py-2">
          Already an Employer?
          <a className="btn btn-outline-primary ms-2" href="/login" role="button">Sign in</a>
        </div>
        <div className="container-fluid   d-lg-block mb-lg-5 py-5 text-center text-white" style={{ background: '#105DBC', backgroundImage: `url(${HomeBanner})`, backgroundSize: 'cover' }}>

          <div className="container position-relative ">
            <h1 className="text-center mb-4 fw-light">Employers</h1>
            <div>
              <p className="text-center fs-5 fw-light">
                We believe in investing the necessary time to get to know the talent we screen,
                ensuring that we bring you only the best candidates. We understand that every hour without the right
                person in a vacant role could be costing your business money.
                That's why delivering intelligent matches based on a deep understanding of the available talent in the market is our specialty.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-lg-5 pt-5">
          <div className="row gx-5 fs-5">
            <div className="col-lg-6 ">
              <h1 className='pb-2'>Staffing Solutions</h1>
              <p>
                Are you getting the support you need to find great workers to drive your business? These days, it’s almost like you need a guide to help you navigate today’s market. That’s where we come in.
                Our staffing services can adjust to your business needs. If you require a service you don’t see listed, we’d be happy to discuss how we can develop a solution for you.
              </p>
            </div>
            <div className="col-lg-6">
              <div class="accordion mt-lg-3" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
                      Temporary staffing
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      Save time and money when managing seasonal peaks, short-term projects, trying to backfill an absent employee, or when the long-term stability of a position is unclear.
                    </div>
                  </div>
                </div>
                <div class="accordion-item pt-3">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Temp-to-Hire Staffing
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      Enhance your workforce by assessing the employee’s fit before you commit. Get a closer look at skills so you can ensure you hire the right person.
                    </div>
                  </div>
                </div>
                <div class="accordion-item pt-3">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed fs-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Permanent Staffing
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      Companies can spend months trying to hire because of the competition for skilled workers. We have the expertise, connections, and speed specifically designed to source your direct hires.
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5" style={{ background: '#f4f6f9' }}>
          <div className="row d-none d-lg-flex">
            <div className="col-lg-6 d-flex align-items-end">
              <div>
                <h1 className="pb-3">Find Talent</h1>
                <p className="fs-5">
                  <strong>Find the reliable staff you need today.</strong> We have available candidates from many industries, including Light Industrial, Production/Assembly, Warehouse, Construction Skilled Labor, Specialty Trades, Technical, Professional, Clerical, Administrative and More. Save time and money, make better use of your resources, and most importantly, achieve your business goals when you work with us.
                </p>
                <p className="fs-5">
                  Contact us today
                  <Link to="/contact" className="btn btn-primary fw-bold ms-3 py-2 px-4">Find Talent ➞</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <img src={Manufacturing} className="img-fluid w-50" alt="..." />
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="row d-lg-none">
            <div className="col-12 d-flex justify-content-center">
              <img src={Manufacturing} className="img-fluid w-75 mb-4" alt="..." />
            </div>
            <div className="col-12">
              <h1 className="pb-3 text-center">Find Talent</h1>
              <p className="fs-5 text-center">
                <strong>Find the reliable staff you need today.</strong> We have available candidates from many industries, including Light Industrial, Production/Assembly, Warehouse, Construction Skilled Labor, Specialty Trades, Technical, Professional, Clerical, Administrative and More. Save time and money, make better use of your resources, and most importantly, achieve your business goals when you work with us.
              </p>
              <div className="text-center">
                <Link to="/contact" className="btn btn-primary fw-bold py-2 px-4">Find Talent ➞</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-5 mb-5 pb-3">
          <div><h1 className="fw-light mb-5">FAQ</h1></div>
          <Accordion className="py-2" defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className="fw-bold fs-4">Employer Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What exactly is an employment service?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    As an employment service, PrairieJobs is the gateway to qualified applicants who’ve already been screened, processed and are available for work.
                    We do the work for you — the weeding out, background checks, drug screens and reference checks. Our Staffing Managers also take time to conduct a thorough and in-depth interview with every applicant, ensuring a successful placement for both the applicant and the employer.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What do PrairieJob’s fees include?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Our fees include employees’ compensation, government taxes, workers’ compensation, and general processing fees.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Does PrairieJobs offer any guarantees?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We offer a four-hour guarantee on every placement. If, within four hours, it’s apparent that an employee is not a good fit for any reason, they can be sent back without any charges to you, the employer.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What if I want to hire a PrairieJobs employee?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    When partnering with PrairieJobs, you’ll be asked to agree to a temp-to-hire contract. Once that agreement has been met, PraireJobs employees are eligible to be hired for no additional fees.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What makes PraireJobs’ employees better than the rest?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The coaching, advice and genuine encouragement given to each employee by our Staffing Managers makes our employees stand out! PrairieJobs recognizes that, if our employees are successful, then our clients will be successful in turn. In the end, that makes PrairieJobs successful too!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What markets does PrairieJobs serve?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PrairieJobs serves Alberta, Saskatchewan, and Manitoba.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What if I need to hire people outside of the markets PrairieJobs serves?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PrairieJobs can be your staffing partner in any market. Just give us a call, and we’ll find employees in any market throughout Canada. You make one call, talk to one person and receive one rate.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Are PrairieJobs employees drug-free?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PrairieJobs will drug test employees at your request, typically within 24 hours of the start of the assignment, to ensure the most reliable results.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">How does PrairieJobs ensure their employees are qualified for placement with my company?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    All applicants undergo an in-depth interview with our experienced Staffing Managers. We also verify employment background and administer written tests to verify skill sets.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Does PrairieJobs have the capability to staff for both small and large projects?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Absolutely! Our top-of-the-line industry software enables us to contact many employees in a very short time. At a moment’s notice, PrairieJobs’ large pool of employees is available to go to work promptly. These projects could require one employee, 20 employees, or many more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </div>

        <Footer />
      </div>

    </>
  );
};

export default Employers;
