import React from 'react';
import Logo from "../assets/image.png";

const Navbar = () => {
    return (
        <>
            <div className="bg-light text-center py-2">
                <p className="m-0 fs-6 p-lg-0 px-4" style={{ color: '#333' }}>Employment Agency for Temporary, Temp-to-Hire, and Permanent Positions</p>
            </div>
            <nav className="navbar navbar-expand-lg sticky-top" style={{ background: 'white' }}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={Logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top mr-2" />
                        <b>PrairieJobs</b>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-lg-auto ">
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/employees">Job Seekers</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/employers">Employers</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/industries">Industries</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/about">About</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center">
                                <a className="nav-link" href="/login">Sign In</a>
                            </li>
                            <li className="nav-item d-flex justify-content-center ms-lg-3">
                                <a className="btn btn-outline-primary px-4" href="/contact" role="button">Join</a>
                            </li>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
