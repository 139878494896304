import Header from "../../components/Header"
import Footer from "../../components/Footer"
import JumbotronImage from '../../assets/jumbotron/Jumbotron.png'
import RegisterIcon from '../../assets/jumbotron/RegisterIcon.png'
import CreateIcon from '../../assets/jumbotron/CreateIcon.png'
import UploadIcon from '../../assets/jumbotron/UploadIcon.png'
import SearchIcon from '../../assets/jumbotron/SearchIcon.png'
import Construction from "../../assets/Construction.png";
import Trade from "../../assets/Trade.png";
import Administration from "../../assets/Administration.png";
import Warehouse from "../../assets/Warehouse.png";
import Customer from "../../assets/Customer.png";
import Manufacturing from "../../assets/Manufacturing.png";
import MobileBanner from "../../assets/Banner.png";
import HomeBanner from "../../assets/HomeBanner.png";
import Section from "../../assets/Section.png";
import Logo from "../../assets/image.png";

function Home() {
  return (
    <>
      <style>
        {`
          a.nav-link {
            color: black;
          }
           a.nav-link.active {
            font-weight: bold;
          } 
        .box_3 {
              display: block;
              height: 120px;
              position: absolute; /* Keep it absolute */
              left: 0; /* Aligns it to the left */
              right: 0; /* Aligns it to the right, making it full width */
              width: 100%; /* Full width of the jumbotron */
              transform: translateY(-20px); /* Translate downwards, adjust as needed */
              z-index: 1; /* Ensures it's above other elements, adjust if necessary */
              box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
              background: #FFFFFF;
        }
          @media (min-width: 992px){
              .custom-spacing {
              margin-top: 200px; /* Adjust the value as needed */
              }
          }
        `}
      </style>
      <div className="d-flex flex-column">
        <div className="fixed-top">
          <div className="bg-light text-center py-2">
            <p className="m-0 fs-6 p-lg-0 px-4" style={{ color: '#333' }}>Employment Agency for Temporary, Temp-to-Hire, and Permanent Positions</p>
          </div>
          <nav className="navbar navbar-expand-lg  " style={{ background: 'white' }}>
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top mr-2" />
                <b>PrairieJobs</b>
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav ms-lg-auto ">
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/">Home</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/employees">Job Seekers</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/employers">Employers</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/industries">Industries</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/about">About</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center">
                    <a className="nav-link" href="/login">Sign In</a>
                  </li>
                  <li className="nav-item d-flex justify-content-center ms-lg-3">
                    <a className="btn btn-outline-primary px-4" href="/contact" role="button">Join</a>
                  </li>
                </div>
              </div>
            </div>
          </nav>
        </div>
        {/**DESKTOP VIEW */}
        <div class=" d-lg-block d-none " style={{ background: "linear-gradient(to bottom, rgba(17, 92, 189, 0.14) 0%, rgba(255, 255, 255, 0) 100%)", maxHeight: '500px', overflow: 'hidden', height: "60vh" }}>
          <div class="jumbotron"
            style={{
              backgroundImage: `url(${JumbotronImage})`, backgroundSize: 'auto', backgroundPosition: 'center 10%', backgroundRepeat: 'no-repeat', maxWidth: '1900px', margin: '0 auto', maxHeight: '500px', overflow: 'hidden', height: "60vh"
            }}>
            <div className="container h-100 d-flex align-items-center ">
              <div className="1 row w-50 fs-1 pt-5"> {/* Text-center to center text */}
                <h1 style={{ fontWeight: "700" }}>
                  <div>Matching Talent With</div>
                  <div> Opportunity</div>
                </h1>
                <p className="fs-6 pt-3">Our focus is on aligning skilled individuals with suitable opportunities, with an emphasis on keeping it simple. We’re not a job board. We’re a local dynamic team that will respond to your employment needs by connecting employees with potential employers through temporary positions, temp-to-hire, or direct-hire positions.</p>
              </div>
            </div>
          </div>
          <div className="container box_3 d-flex justify-content-center align-items-center ">
            <div className="row w-100">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className=" row justify-content-center">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <img src={RegisterIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    Register
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-center">
                <div className=" row justify-content-center">
                  <div className="col-lg-12 d-flex justify-content-center ">
                    <img src={CreateIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    Send your Resume
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-center">
                <div className=" row justify-content-center">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <img src={UploadIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    Join the System
                  </div>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-center">
                <div className=" row justify-content-center">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <img src={SearchIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    Get Work
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**SMALL SCREEN VIEW */}
        <div className="container d-lg-none d-flex justify-content-center pt-5 mt-5"
          style={{
            backgroundImage: `url(${MobileBanner})`, backgroundSize: 'auto', backgroundPosition: '20% 50%', backgroundRepeat: 'no-repeat', maxWidth: '1900px', margin: '0 auto', maxHeight: '500px', overflow: 'hidden', height: "50vh"
          }}>
          <div className="h-100 d-flex align-items-center pt-3">
            <div className="1 row text-center text-white fs-1"> {/* Text-center to center text */}
              <h1 style={{ fontWeight: "700" }}>
                <div>Matching Talent With</div>
                <div> Opportunity</div>
              </h1>
              <p className="fs-6 pt-3">Our focus is on aligning skilled individuals with suitable opportunities, with an emphasis on keeping it simple. We’re not a job board. We’re a local dynamic team that will respond to your employment needs by connecting employees with potential employers through temporary positions, temp-to-hire, or direct-hire positions.</p>
            </div>
          </div>
        </div>
        <div className="container custom-spacing ">
          <h1 className="fs-1 text-lg-start text-center mt-lg-0 mt-5">Industries We Serve</h1>
          <p className='fs-5'> PrairieJobs offers staffing services for temporary, temp-to-hire and permanent placements in a variety of different industries. Whether you need help finding a flexible, hourly job you’ll love, or you need help finding the right temporary workers to keep your business operations running smoothly, PrairieJobs can be your strategic partner.</p>
          <div className="row mt-5 gx-5">
            <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center px-lg-0">
              <a href="/industries#skilledtrade" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }} >
                  <img src={Trade} class="card-img" alt="..." />
                  <div class="card-img-overlay d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Skilled Trade</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 d-flex justify-content-center px-lg-0">
              <a href="/industries#administration" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }}>
                  <img src={Administration} class="card-img" height="100%" alt="..." />
                  <div class="card-img-overlay  d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Administration</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center px-lg-0">
              <a href="/industries#manufacturing" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }}>
                  <img src={Manufacturing} class="card-img" height="100%" alt="..." />
                  <div class="card-img-overlay  d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Manufacturing</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="row mt-lg-4 mt-2 mb-5 pb-5 gx-5">
            <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center px-lg-0">
              <a href="/industries#warehouse" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }}>
                  <img src={Warehouse} class="card-img" alt="..." />
                  <div class="card-img-overlay d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Warehouse</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 d-flex justify-content-center px-lg-0">
              <a href="/industries#customerservice" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }}>
                  <img src={Customer} class="card-img" height="100%" alt="..." />
                  <div class="card-img-overlay d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Customer Service</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center px-lg-0">
              <a href="/industries#construction" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div class="card text-white clickable-card" style={{ border: 'none', cursor: 'pointer' }}>
                  <img src={Construction} class="card-img" height="100%" alt="..." />
                  <div class="card-img-overlay  d-flex justify-content-center align-items-center">
                    <h3 class="card-title">Construction</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="container-fluid  p-5 " style={{ background: '#105DBC', backgroundImage: `url(${HomeBanner})`, backgroundPosition: ' center 3%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <div className="container">
            <div className="row text-white">
              <div className="col-lg-6 text-lg-start text-center">
                <h1 className="fw-bold mb-3">Find a Job</h1>
                <p className="fw-semibold">You don’t have to navigate the job search alone with PrairieJobs as your guide. We’ve been helping job seekers in Western Canada find employment in light industrial, construction, and clerical roles since 2023. We will take the time to understand your talents so that you can easily and quickly land the best opportunity.</p>
              </div>
              <div className="col-lg-6 d-lg-flex align-items-center justify-content-end d-none fw-bold" >
                <a href="/contact" className="text-white" style={{ textDecoration: 'none' }}><h1>Contact Us ➤</h1></a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid d-lg-block d-none pt-5 mb-5 pb-5"
          style={{
            backgroundImage: `url(${Section})`,
            backgroundPosition: 'center 3%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="container py-1">
            <div className="row gx-5">
              <div className="col-lg-6 d-flex justify-content-end text-center border-end text-white">
                <div className="d-flex flex-column" style={{ height: '400px' }}>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <h1>Employers: We do the extra work to ensure the right fit</h1>
                  </div>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <p className="fs-5 mt-4">
                      We gather information about your business needs and staffing requirements. Then, we find the best fit for your needs among our vetted pool of potential employees.
                    </p>
                  </div>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <a href="/employers" className="btn btn-primary btn-lg w-25">Find Talent</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-end text-center border-start">
                <div className="d-flex flex-column" style={{ height: '400px' }}>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <h1>Job Seekers: Find a great fit</h1>
                  </div>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <p className="fs-5 mt-4">
                      We will learn about your skill set and what you’re ultimately looking for when taking the next step in your career. Then, we will use our expertise and connections to help you achieve your employment goals.
                    </p>
                  </div>
                  <div className="flex-fill d-flex align-items-center justify-content-center" style={{ height: '33.33%' }}>
                    <a href="/employees" className="btn btn-primary btn-lg w-25">Find Work</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**SMALL SCREEN */}
        <div className="container-fluid d-lg-none pt-5 mb-lg-5 pb-lg-5">
          <div className="container py-5">
            <div className="row gx-5">
              <div className="col-lg-6 d-flex justify-content-end text-center">
                <div>
                  <h1>Employers: We do the extra work to ensure the right fit.</h1>
                  <p className=" fs-5 mt-1">
                    We gather information about your business needs and staffing requirements. Then, we find the best fit for your needs among our vetted pool of potential employees.
                  </p>
                  <a href="/employers" class="btn btn-primary btn-lg w-40 mt-5">Find Talent</a>
                </div>
              </div>
              <div className="col-lg-6 mt-5 d-flex justify-content-end text-center border-top pt-5">
                <div>
                  <h1>Job Seekers: Find a great fit</h1>
                  <p className=" fs-5 mt-4">
                    We will learn about your skill set and what you’re ultimately looking for when taking the next step in your career. Then, we will use our expertise and connections to help you achieve your employment goals.
                  </p>
                  <a href="/employees" class="btn btn-primary btn-lg w-40 mt-5">Find Work</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5 pb-5">
          <h1 className="text-center   mt-5">What People Say</h1>
          <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-interval="false" datas-interval="false">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="row py-5 px-2">
                  <div class="col-lg-4 d-flex justify-content-lg-start justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Got a good-paying job!</h5>
                        <p class="card-text">“The recruiters at PrairieJobs are caring and personable individuals. I highly recommend going to them for your employment needs. PrairieJobs got me a good-paying job as a labourer for a landscaping company. I love my job, and it’s all thanks to PrairieJobs.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Brandon S., PrairieJobs Candidate
                        </figcaption>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Very satisfied with the staffing services</h5>
                        <p class="card-text">“We are extremely satisfied with the staffing services provided by PrairieJobs and the skills of our workers. This enables us to manage our labour requirements effectively and adjust our capacity as needed.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Matt P., PrairieJobs Client
                        </figcaption>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-lg-end justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">My recruiter was excellent</h5>
                        <p class="card-text">“My recruiter at PrairieJobs was excellent in terms of communication about the jobs available in my local area. They answered all my questions and concerns about the openings we discussed. I would love to have PrairieJobs help me find another job in the future should I need it.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Leonard P., PrairieJobs Candidate
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item active">
                <div className="row py-5 px-2">
                  <div class="col-lg-4 d-flex justify-content-lg-start justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Always friendly and helpful</h5>
                        <p class="card-text">“The PrairieJobs team is always friendly and helpful. They show concern for our business needs and the employees they place.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Joe S., PrairieJobs Client
                        </figcaption>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Contacted me 4 hours later with a job</h5>
                        <p class="card-text">“PrairieJobs contacted me immediately after my interview in Regina, Saskatchewan and assured me that they would find work that fits my experience/criteria as soon as possible. They contacted me 4 hours later with a job opportunity and interview set up for the following day.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Sarah T., PrairieJobs Candidate
                        </figcaption>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 d-flex justify-content-lg-end justify-content-center mb-lg-0 mb-3" style={{ height: "22rem" }}>
                    <div class="card shadow border-0" style={{ width: "18rem" }}>
                      <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Great at finding us strong candidates</h5>
                        <p class="card-text">“We have worked with PrairieJobs for over a year now. Their team does a great job of getting us solid help that fits our needs at the time.”</p>
                        <figcaption class="blockquote-footer mt-auto fs-6">
                          Troy R., PrairieJobs Client
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex d-none">
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ left: '-10%' }}>
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style={{ right: '-10%' }}>
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Home