import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

function NotFound() {
  return (
    <div style={{ height: '100vh' }} className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
        <h1>404 - Page Not Found</h1>
        <p>The page you're looking for doesn't exist.</p>

        <Link to="/">
          <button className="btn btn-primary">Return to Home</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;
