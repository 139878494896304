import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { useState, useEffect, Fragment } from 'react';

import Header from "../../components/admin/Header"
import SideNav from "../../components/admin/SideNav"


const generateWeekDays = (weekStart) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startDate = new Date(weekStart);

    return days.map((day, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + index);
        return {
            day,
            date: currentDate.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC'
            })
        };
    });
};

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>

                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.numEmployees}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>

                            {row.timeCards.map((item) => {
                                const weekDays = generateWeekDays(item.weekStart);
                                return (

                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} className='fw-bold'>{item.fullname}</TableCell>
                                                <TableCell colSpan={1}><Chip label="Pending" color="warning" variant="outlined" /></TableCell>
                                                <TableCell colSpan={4} className='fw-bold'>Week of {item.week}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                            {weekDays.map((day, index) => (
                                                    <TableCell key={index}>{day.day}, {day.date}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={item.id}>
                                                <TableCell component="th" scope="row">
                                                    {item.sunday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.monday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.tuesday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.wednesday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.thursday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.friday}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.saturday}
                                                </TableCell>

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            }
                            )}


                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired,
        numEmployees: PropTypes.number.isRequired,
        daily_hours: PropTypes.arrayOf(
            PropTypes.shape({
                sunday: PropTypes.string.isRequired,
                monday: PropTypes.string.isRequired,
                tuesday: PropTypes.string.isRequired,
                wednesday: PropTypes.string.isRequired,
                thursday: PropTypes.string.isRequired,
                friday: PropTypes.string.isRequired,
                saturday: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};


const formatWeekDays = (daily_hours) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return days.map((day, index) => {
        const hours = daily_hours[index];
        return hours
            ? hours.start_time + ' - ' + hours.end_time
            : 'OFF'; // Default to 'OFF' if no hours for the day
    });
};

const formatWeek = (weekStart, weekEnd) => {
    const start = new Date(weekStart).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC', // Ensure consistent formatting across timezones
    });
    const end = new Date(weekEnd).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    });
    return `${start} - ${end}`;
};

function TimeCard() {
    const [rows, setRows] = useState([]);
    const getTimecards = async () => {
        const response = await axios.get('/api/timecards/getAdminTimecards')
        const { data } = response.data;

        const transformedData = data.map(employer => {
            return {
                name: employer.employer_name,
                status: 'Active',
                numEmployees: employer.employees.length,
                timeCards: employer.employees.map(employee => {

                    const timecard = employee.time_cards && employee.time_cards.length > 0 ? employee.time_cards[0] : null;
                    const weekDays = timecard ? formatWeekDays(timecard.daily_hours) : Array(7).fill('OFF');
                    return {
                        fullname: employee.employee_name,
                        status: employee.time_cards[0]?.status || 'N/A',
                        id: employee.employee_id,
                        week: timecard ? formatWeek(timecard.week_start, timecard.week_end) : 'N/A',
                        weekStart: timecard.week_start,
                        sunday: weekDays[0],
                        monday: weekDays[1],
                        tuesday: weekDays[2],
                        wednesday: weekDays[3],
                        thursday: weekDays[4],
                        friday: weekDays[5],
                        saturday: weekDays[6],
                    };
                }),
            };
        });
        console.log(transformedData);

        setRows(transformedData);
        return transformedData;
    };
    useEffect(() => {
        getTimecards();
    }, [])
    return (
        <>
            <div style={{ height: '90vh' }}>
                <Header />
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-2 d-none d-lg-block d-md-block">
                            <SideNav />
                        </div>
                        <div className="col-lg-10 ps-lg-5 mt-5">
                            <div className="row d-flex justify-content-end h-75">
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />

                                                <TableCell>Employer</TableCell>
                                                <TableCell align="right">
                                                    Status
                                                </TableCell>
                                                <TableCell align="right"># Employees</TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <Row key={row.name} row={row} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeCard