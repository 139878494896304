import React from 'react';
import Logo from "../assets/image.png";
import LinkedInLogo from "../assets/linkedin.png";

const Footer = () => {
    return (
        <>
        {
            /**
             *<div className="container-fluid mt-auto" style={{ background: '#262626' }}>
                <div className="container text-white py-4">
                    <footer className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-3 mb-sm-0">
                            <a href="/" className="text-decoration-none">
                                <img src={Logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top me-2" />
                            </a>
                            <span>© 2024 PrairieJobs</span>
                        </div>

                    <ul className="nav list-unstyled d-flex flex-wrap justify-content-center">
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/employees">Employees</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/employers">Employers</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/industries">Industries</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/about">About</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/contact">Contact</a></li>
                    </ul>
                </footer>
                
                <div className="text-center mt-4">
                    <p className="mb-1">4270 E Buckingham Dr., Regina, SK, S4V 3Y9</p>
                    <p className="mb-1">Phone: <a href="tel:+13065650789" className="text-white"> (306) 565-0789</a></p>
                    <p>Email: <a href="mailto:contact@PrairieJobs.ca" className="text-white">contact@PrairieJobs.ca</a></p>
                    <div className="ms-3">
                        <a href="https://www.linkedin.com/company/prairie-jobs-inc" target="_blank" rel="noopener noreferrer" className="text-white">
                            <img src={LinkedInLogo} alt="LinkedIn" width="100" className="d-inline-block align-text-top" />
                        </a>
                    </div>
                </div>
            </div>
             */
        }
            


            <div class="container-fluid mt-auto text-white" style={{ background: '#262626' }}>
                <div className="container">
                    <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 mt-5 border-top">
                        <div class="col mb-3 ">
                            <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                                <a href="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                                    <img src={Logo} alt="Logo" width="50" height="50" className="d-inline-block align-text-top me-2" />
                                </a>
                                <h3>PrairieJobs</h3>
                            </div>


                        </div>

                        <div class="col mb-1">

                        </div>

                        <div class="col mb-1 text-center text-lg-start">
                            <h5>Explore</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="/employees" class="nav-link p-0 text-white">Job Seekers</a></li>
                                <li class="nav-item mb-2"><a href="/employers" class="nav-link p-0 text-white">Employers</a></li>
                                <li class="nav-item mb-2"><a href="/industries" class="nav-link p-0 text-white">Industries</a></li>
                                <li class="nav-item mb-2"><a href="/about" class="nav-link p-0 text-white">About</a></li>
                            </ul>
                        </div>

                        <div class="col mb-1 text-center text-lg-start">
                            <h5>Connect</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="https://www.linkedin.com/company/prairie-jobs-inc" class="nav-link p-0 text-white">LinkedIn</a></li>
                                <li class="nav-item mb-2"><a href="/contact" class="nav-link p-0 text-white">Contact</a></li>
                            </ul>
                        </div>

                        <div class="col mb-1 text-center text-lg-start">
                            <h5>Get in Touch</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="https://maps.app.goo.gl/JtQPVtJNwUCVAyzV6" class="nav-link p-0 text-white">4270 E Buckingham Dr., Regina, SK, S4V 3Y9</a></li>
                                <li class="nav-item mb-2"><a href="tel:+13065650789" class="nav-link p-0 text-white">(306) 565-0789</a></li>
                                <li class="nav-item mb-2"><a href="mailto:contact@PrairieJobs.ca" class="nav-link p-0 text-white"> Contact@PrairieJobs.ca</a></li>

                            </ul>
                        </div>
                    </footer>
                    <div className="text-center">
                        <p className='fw-lighter'> PrairieJobs © 2024</p>
                    </div>
                </div>

            </div>
        </>



    );
}

export default Footer;
