import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState, useEffect, useRef } from 'react';
import { Button, Box, TextField } from '@mui/material';
import axios from 'axios';
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, useGridApiContext } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Header from '../components/admin/Header';
import Calendar from '../components/employee/Calendar';

//function to get the current week
function getCurrentWeek() {
    const today = new Date(); // Get the current date
    const dayOfWeek = today.getUTCDay(); // Get the current day of the week (0 = Sunday, 6 = Saturday)
    // Calculate the start of the week (Sunday)
    const startOfWeek = new Date(today);
    startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek); // Move back to the previous Sunday
    // Calculate the end of the week (Saturday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6); // Move forward to Saturday
    return {
        week_start: startOfWeek.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC' // Force UTC to avoid timezone issues
        }),
        week_end: endOfWeek.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC' // Force UTC to avoid timezone issues
        })
    };
}

//top most tool bar renderer for the table
function EditToolbar(props) {
    const { setRows, setRowModesModel, currentWeek } = props;
    const [data, setdata] = useState(null);
    useEffect(() => {
        if (data) {
            var dateString = data.week_start
            var date = new Date(dateString);
            var formatStart = date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC' // Force UTC to avoid timezone issues
            });

            dateString = data.week_end;
            date = new Date(dateString);
            const formatEnd = date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC' // Force UTC to avoid timezone issues
            });
            setRows((oldRows) => [
                ...oldRows,
                { id: data._id, week:formatStart + " - " +formatEnd,  Sun: '', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', isNew: true },
            ]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [data._id]: { mode: GridRowModes.Edit, fieldToFocus: 'Sun' },
            }));
        }
    }, [data])
    const handleClick = async () => {


        const employeeid = JSON.parse(localStorage.getItem('user'))._id;
        
        const dataToSend = {
            id: employeeid,
        };
        try {
            // Send the data to the server
            const response = await axios.post('/api/timecards/createEmployee', dataToSend)
            const { data } = response.data;
            setdata(data);
        } catch (e) {
            console.log(e);
        }



    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record for Week of {currentWeek.week_start} - {currentWeek.week_end}
            </Button>
        </GridToolbarContainer>
    );
}


function TimeEditInputCell(props) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = useRef(null);

    // Split the value into start and end times
    const [startTime, endTime] = value.split(' - ');

    const handleStartTimeChange = (event) => {
        const newStartTime = event.target.value ;
        const newValue = `${newStartTime} - ${endTime}`;
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 });
    };

    const handleEndTimeChange = (event) => {
        const newEndTime = event.target.value;
        const newValue = `${startTime} - ${newEndTime}`;
        apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 });
    };

    useEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.querySelector('input').focus();
        }
    }, [hasFocus]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
                ref={ref}
                type="time"
                value={startTime}
                onChange={handleStartTimeChange}
                size="small"
                sx={{ mb: 0.2, }}
            />
            <TextField
                type="time"
                value={endTime}
                onChange={handleEndTimeChange}
                size="small"
            />
        </Box>
    );
}
function renderTimeRange(params) {
    const [start, end] = params.value.split(' - ');
    return <span>{`${start} - ${end}`}</span>;
}
const renderTimeRangeEditInputCell = (params) => {
    return <TimeEditInputCell {...params} />;
};

function Employee() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});
    const [employer, setEmployer] = useState("Select Employer");
    const employeeid = JSON.parse(localStorage.getItem('user'))._id;
    const [rows, setRows] = useState([]);

    const currentWeek = getCurrentWeek();
    //console.log('Week Start:', currentWeek.week_start);
    //console.log('Week End:', currentWeek.week_end);

    const fetchTimeCards = async (employeeId, page) => {
        try {
            const response = await axios.get(`/api/timecards/getEmployee/${employeeId}`, {
                params: {
                    page: page
                }
            });

            const { data } = response.data;
            const rowdata = [];
            data.forEach(element => {
                var dateString = element.week_start;
                var date = new Date(dateString);
                var formatStart = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC' // Force UTC to avoid timezone issues
                });

                dateString = element.week_end;
                date = new Date(dateString);
                const formatEnd = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC' // Force UTC to avoid timezone issues
                });
                rowdata.push({
                    id: element._id,
                    week: formatStart + " - " + formatEnd,
                    Sun: element.daily_hours[0] ? element.daily_hours[0].start_time + " - " + element.daily_hours[0].end_time : "OFF",
                    Mon: element.daily_hours[1] ? element.daily_hours[1].start_time + " - " + element.daily_hours[1].end_time : "OFF",
                    Tue: element.daily_hours[2] ? element.daily_hours[2].start_time + " - " + element.daily_hours[2].end_time : "OFF",
                    Wed: element.daily_hours[3] ? element.daily_hours[3].start_time + " - " + element.daily_hours[3].end_time : "OFF",
                    Thu: element.daily_hours[4] ? element.daily_hours[4].start_time + " - " + element.daily_hours[4].end_time : "OFF",
                    Fri: element.daily_hours[5] ? element.daily_hours[5].start_time + " - " + element.daily_hours[5].end_time : "OFF",
                    Sat: element.daily_hours[6] ? element.daily_hours[6].start_time + " - " + element.daily_hours[6].end_time : "OFF"
                })

            });
            //console.log(rowdata);

            setRows(rowdata);

        } catch (error) {
            //console.error('Error fetching time cards:', error);
        }
    };
    useEffect(() => {
        fetchTimeCards(employeeid, "1");
    }, [])

    const handleSelectEmployer = (company) => {
        setEmployer(company);
    }

    const columns = [
        { field: 'week', headerName: 'Week', width: 120, editable: false },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 75,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        { field: 'Sun', headerName: 'Sunday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Mon', headerName: 'Monday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Tue', headerName: 'Tuesday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Wed', headerName: 'Wednesday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Thu', headerName: 'Thursday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Fri', headerName: 'Friday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell },
        { field: 'Sat', headerName: 'Saturday', width: 157, editable: true, renderCell: renderTimeRange, renderEditCell: renderTimeRangeEditInputCell }
    ]

    const handleClickCal = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    const handleSaveClick = (id) => async () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        //send the  updated row data to the server once it is saved
    };

    //console.log(rows);
    const update = async (row) => {

        const dailyHours = [
            { day: 'Sun', start_time: row.Sun.split(' - ')[0], end_time: row.Sun.split(' - ')[1] },
            { day: 'Mon', start_time: row.Mon.split(' - ')[0], end_time: row.Mon.split(' - ')[1] },
            { day: 'Tue', start_time: row.Tue.split(' - ')[0], end_time: row.Tue.split(' - ')[1] },
            { day: 'Wed', start_time: row.Wed.split(' - ')[0], end_time: row.Wed.split(' - ')[1] },
            { day: 'Thu', start_time: row.Thu.split(' - ')[0], end_time: row.Thu.split(' - ')[1] },
            { day: 'Fri', start_time: row.Fri.split(' - ')[0], end_time: row.Fri.split(' - ')[1] },
            { day: 'Sat', start_time: row.Sat.split(' - ')[0], end_time: row.Sat.split(' - ')[1] }
        ];
        const dataToSend = {
            id: row.id, // Send the ID of the updated row
            daily_hours: dailyHours // Send the daily hours
        };
        try {
            // Send the data to the server
            const response = await axios.put('/api/timecards/updateEmployee', dataToSend);
            console.log('Response from server:', response.data);
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };
    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };
    //process row update actually handles the "saving" of the row data to rows and is used to send the updated value to the server
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        console.log("row loaded?");
        console.log(updatedRow);
        update(updatedRow);
        return updatedRow;

    };
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    return (
        <>
            <Header />
            <div style={{ height: '85%' }}>
                <div className="container h-100 pt-5">
                    <div className="row">
                        <div className="col-lg-6  text-start ">
                            <span class="btn-group me-3" role="group" aria-label="Basic outlined example">
                                <button type="button" class="btn btn-outline-primary">Weekly</button>
                                <button type="button" class="btn btn-outline-primary">Summary</button>
                            </span>
                            <span class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {employer}
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" onClick={() => { handleSelectEmployer("Company 1") }}>Company 1</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={() => { handleSelectEmployer("Company 2") }}>Company 2</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={() => { handleSelectEmployer("Company 3") }}>Company 1</a></li>
                                </ul>
                            </span>

                        </div>
                        <div className="col-lg-6">
                            <div className='row'>
                                <div className='col-lg-12 w-100 text-lg-end mt-2 mt-lg-0 mt-md-0 pe-0'>
                                    <span class="btn-group me-3" role="group" aria-label="Basic outlined example">
                                        <button type="button" class="btn btn-outline-primary">This Week</button>
                                        <button type="button" class="btn btn-outline-primary d-flex align-items-center ">
                                            <span class="material-symbols-outlined ">
                                                arrow_back_ios
                                            </span>
                                        </button>
                                        <button type="button" class="btn btn-outline-primary d-flex align-items-center ">
                                            <span class="material-symbols-outlined ">
                                                arrow_forward_ios
                                            </span>
                                        </button>
                                        <button type="button" class="btn btn-outline-primary" onClick={handleClickCal}>
                                            <span class="material-symbols-outlined cal">
                                                calendar_month
                                            </span>
                                        </button>
                                        {/**Celndar.jsx */}
                                        <Calendar anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className=" mt-5" style={{ width: '100%' }}>
                        <Box sx={{ height: 400, width: '100%' }} >
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                getRowHeight={() => 'auto'}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                isCellEditable={(params) => params.row.id !== 2}
                                slots={{
                                    toolbar: EditToolbar,
                                }}
                                slotProps={{
                                    toolbar: { setRows, setRowModesModel, currentWeek },
                                }}
                                style={{ height: 400, width: '100%', overflowX: 'auto' }}
                            />
                        </Box>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Employee