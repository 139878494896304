import React from "react";
import Navbar from "../components/Header";
import Logo from "../assets/image.png";
import axios from "axios";
import { ContextProvider } from "../App";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordMatch, setNewPasswordMatch] = React.useState("");
  const { id } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (newPassword !== newPasswordMatch) {
      alert("Passwords do not match");
      return;
    }
    try {
      const response = await axios.put(`/api/admins/reset-password/${id}`, {
        password: newPassword,
      });
      if (response.status === 200 || response.status === 201) {
        alert("Password reset successfully");
        window.location.href = "/login";
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Navbar />
      <div class="flex min-h-[85vh] flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                New Password
              </label>
              <div class="mt-2">
                <input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Re-enter The Password
                </label>
              </div>
              <div class="mt-2">
                <input
                  value={newPasswordMatch}
                  onChange={(e) => setNewPasswordMatch(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                onClick={handleLogin}
                type="submit"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset Password
              </button>
            </div>
          </form>
          <p class="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/contact"
              class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Reach out to the admin for help
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ResetPassword;
