import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';

import { useState, Fragment, useEffect } from 'react';
import Header from '../components/admin/Header';
import Dropdown from '../components/employer/Dropdown';
import Calendar from '../components/employee/Calendar';



function getCurrentWeek() {
    const today = new Date();
    const dayOfWeek = today.getUTCDay();
    const startOfWeek = new Date(today);
    startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6);
    return {
        week_start: startOfWeek.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC'
        }),
        week_end: endOfWeek.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC'
        })
    };
}
const generateWeekDays = (weekStart) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startDate = new Date(weekStart);

    return days.map((day, index) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + index);
        return {
            day,
            date: currentDate.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC'
            })
        };
    });
};
function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const currentWeek = getCurrentWeek();
    

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>

                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            {row.daily_hours.map((item) => {
                                const weekDays = generateWeekDays(item.weekStart);
                                return (
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} className='fw-bold'>Week of {item.week}</TableCell>
                                                <TableCell colSpan={4} className='fw-bold'>
                                                    <Dropdown id={item.id} initialstatus={item.status} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {weekDays.map((day, index) => (
                                                    <TableCell key={index}>{day.day}, {day.date}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={item.id}>
                                                <TableCell component="th" scope="row">
                                                    {item.Sun}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Mon}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Tue}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Wed}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Thu}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Fri}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.Sat}
                                                </TableCell>

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        daily_hours: PropTypes.arrayOf(
            PropTypes.shape({
                sunday: PropTypes.string.isRequired,
                monday: PropTypes.string.isRequired,
                tuesday: PropTypes.string.isRequired,
                wednesday: PropTypes.string.isRequired,
                thursday: PropTypes.string.isRequired,
                friday: PropTypes.string.isRequired,
                saturday: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};


function Employer() {
    const employerId = JSON.parse(localStorage.getItem('user'))._id;
    const [data, setData] = useState([]);
    const currentWeek = getCurrentWeek();
    const [anchorEl, setAnchorEl] = useState(null);
    const [employer, setEmployer] = useState("Select Employer");

    async function fetchTime() {
        const response = await axios.get(`/api/timecards/getEmployerTimecards/${employerId}`);
        const { data } = response.data;
        setData(data);
    }

    useEffect(() => {
        fetchTime();
    }, []);

    const rows = [];
    data.forEach((employee) => {
        const daily_hours = [];
        employee.time_cards.forEach((time) => {
            var dateString = time.week_start;
            var date = new Date(dateString);
            var formatStart = date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC' // Force UTC to avoid timezone issues
            });
            dateString = time.week_end;
            date = new Date(dateString);
            const formatEnd = date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                timeZone: 'UTC' // Force UTC to avoid timezone issues
            });
            const daily_object = {
                id: time._id,
                week: formatStart + " - " + formatEnd,
                weekStart: time.week_start,
                status: time.status,
                Sun: time.daily_hours[0] ? time.daily_hours[0].start_time + " " + time.daily_hours[0].end_time : "OFF",
                Mon: time.daily_hours[1] ? time.daily_hours[1].start_time + " - " + time.daily_hours[1].end_time : "OFF",
                Tue: time.daily_hours[2] ? time.daily_hours[2].start_time + " - " + time.daily_hours[2].end_time : "OFF",
                Wed: time.daily_hours[3] ? time.daily_hours[3].start_time + " - " + time.daily_hours[3].end_time : "OFF",
                Thu: time.daily_hours[4] ? time.daily_hours[4].start_time + " - " + time.daily_hours[4].end_time : "OFF",
                Fri: time.daily_hours[5] ? time.daily_hours[5].start_time + " - " + time.daily_hours[5].end_time : "OFF",
                Sat: time.daily_hours[6] ? time.daily_hours[6].start_time + " - " + time.daily_hours[6].end_time : "OFF"
            }
            daily_hours.push(daily_object);
        })
        const object = {
            name: employee.employee_name,
            status: employee.status,
            daily_hours: daily_hours
        }
        rows.push(object);
    })

    const handleClickCal = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelectEmployer = (company) => {
        setEmployer(company);
    }

    return (
        <>
            <div style={{ height: '90vh' }}>
                <Header />
                <div className="container">
                    <div className="row mt-5">
                        <div className='col-lg-12 w-100 text-lg-end mt-2 mt-lg-0 mt-md-0'>
                            <span class="btn-group me-3" role="group" aria-label="Basic outlined example">
                                <button type="button" class="btn btn-outline-primary">This Week</button>
                                <button type="button" class="btn btn-outline-primary d-flex align-items-center ">
                                    <span class="material-symbols-outlined ">
                                        arrow_back_ios
                                    </span>
                                </button>
                                <button type="button" class="btn btn-outline-primary d-flex align-items-center ">
                                    <span class="material-symbols-outlined ">
                                        arrow_forward_ios
                                    </span>
                                </button>
                                <button type="button" class="btn btn-outline-primary" onClick={handleClickCal}>
                                    <span class="material-symbols-outlined cal">
                                        calendar_month
                                    </span>
                                </button>
                                {/**Celndar.jsx */}
                                <Calendar anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                            </span>
                        </div>
                    </div>
                    <div className="row mt-5 ">

                        <div className="row mb-5">
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='fw-bold fs-5'>Current Week {currentWeek.week_start} - {currentWeek.week_end}</TableCell><TableCell />

                                            <TableCell className='fw-bold fs-5'>Employee</TableCell>
                                            <TableCell align="right">
                                            </TableCell>
                                            <TableCell align="right">
                                            </TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <Row key={row.name} row={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Employer